.Entete {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 50px;
  box-sizing: border-box;
}

.BoutonRdvMobile {
  display: none;
}

.MenuMobile {
  display: none;
}

@media (max-width: 640px) {
  .Entete {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    padding-right: 20px;
    justify-content: space-around;
    box-sizing: border-box;
    max-width: 100%;
  }

  .Logo {
    max-width: 80%;
    height: 140px;
  }

  .BoutonRdvMobile {
    display: none;
    position: sticky;
    top: 0px;
  }

  .MenuMobile {
    display: flex;
  }

  .BoutonRdv {
    display: none;
  }
}
