.PageMentionsLegales {
  padding: 40px;
  font-size: 0.8em;
  color: #666666;
}

@media (max-width: 640px) {
  .PageMentionsLegales {
    padding: 20px;
  }
}
