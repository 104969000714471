.PlanningFormations {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.Jour {
  flex: 1;
}

.NomJour {
  text-align: center;
  padding: 10px;
  font-weight: 700;
  color: #666;
}

@media (max-width: 640px) {
  .PlanningFormations {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .NomJour {
    text-align: center;
    padding: 10px;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #2db7bc;
    font-size: 20px;
  }
}
