.App {
  max-width: 1200px;
  margin: auto;
  background-color: white;
  font-family: "Lato";
  color: black;
  font-size: 1.2em;
  font-weight: 300;
  box-shadow: 2px 4px 12px 0 rgba(0, 0, 0, 0.2);
  position: relative;
}

/************** LES BLOC ***********/
article {
  margin-top: 40px;
}

/************** LES LIENS ***********/
a:link {
  text-decoration: none;
}

li {
  margin: 10px 0px;
}
/* NES STYLES TEXTES */

p {
  text-align: justify;
}

mark {
  background-color: initial;
  font-weight: bolder;
  color: inherit;
}

.TitreBlanc {
  font-family: "Lato";
  font-size: 40px;
  color: white;
  font-weight: 300;
  text-transform: uppercase;
}

.TitrePopup {
  font-family: "Lato";
  font-size: 30px;
  color: #005799;
  font-weight: 300;
}

.TitreBleu {
  font-family: "Lato";
  font-size: 40px;
  color: #005799;
  font-weight: 300;
  text-transform: uppercase;
}

.TitreTurquoise {
  font-family: "Lato";
  font-size: 40px;
  color: #2db7bc;
  font-weight: 300;
  text-transform: uppercase;
}

.TitreGris {
  font-family: "Lato";
  font-size: 40px;
  color: #33333350;
  font-weight: 300;
  text-transform: uppercase;
}

.Titre2 {
  font-family: Lato;
  font-weight: 700;
  margin: 5px 0px;
  font-size: 20px;
  color: #2db7bc;
  letter-spacing: 0;
}

.Titre2Blanc {
  font-family: Lato;
  font-weight: 700;
  margin: 5px 0px;
  font-size: 20px;
  color: #ffffff;
  letter-spacing: 0;
}
.Titre2Vert {
  font-family: Lato;
  font-weight: 700;
  margin: 5px 0px;
  font-size: 20px;
  color: #a1bb1a;

  letter-spacing: 0;
}

.SousTitre {
  color: #666;
}

.Titre3Noir {
  font-family: Lato;
  font-weight: 300;
  margin: 0px 0px;
  font-size: 20px;
  color: #666;
  letter-spacing: 0;
}

@media (max-width: 640px) {
  /* NES STYLES TEXTES */
  .TitreBlanc,
  .TitreBleu,
  .TitreTurquoise,
  .TitreGris {
    font-size: 30px;
  }

  * {
    box-sizing: border-box;
  }
  /* passer body (et tous les éléments de largeur fixe) en largeur automatique */

  body {
    width: auto;
    margin: 0;
    padding: 0;
  }
  /* fixer une largeur maximale de 100% aux éléments potentiellement problématiques */

  img,
  table,
  td,
  blockquote,
  code,
  pre,
  textarea,
  input,
  iframe,
  object,
  embed,
  video {
    max-width: 100%;
  }

  /* conserver le ratio des images */

  img {
    height: auto;
  }

  /* gestion des mots longs */

  textarea,
  table,
  td,
  th,
  code,
  pre,
  samp {
    -webkit-hyphens: auto; /* césure propre */
    -moz-hyphens: auto;
    hyphens: auto;
    word-wrap: break-word; /* passage à la ligne forcé */
  }

  /* masquer les éléments superflus */

  .hide_mobile {
    display: none !important;
  }
}
