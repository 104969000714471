.Bloc404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 40px;
}

@media (max-width: 640px) {
  .Bloc404 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 20px;
  }
}
