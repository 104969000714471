.Menu {
  color: white;
  margin: 0px;
  display: flex;
  flex-direction: row;
  min-height: 40px;
  background-color: #005799;
  padding: 0px;
}

.ItemMenu {
  flex: 1;
}

@media (max-width: 640px) {
  .Menu {
    display: none;
  }
}
