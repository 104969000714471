.Bloc1 {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.TexteEtBoutonBloc1 {
  padding: 0px 60px;
}

.BoutonBloc1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ImageBloc1 {
  border-radius: 0px 0px 0px 80px;
  border-left: 8px solid #2db5ba;
  box-shadow: -8px 0px 0px 0 rgba(156, 191, 22, 1);
  margin-left: 80px;
  height: 250px;
}

.Bloc2 {
  margin-top: 20px;
  padding: 60px 60px 0px;
  background-color: #f6f6f6;
}

.TexteBloc2 {
  text-align: justify;
  padding-bottom: 10px;
}

.Bloc3 {
  background-color: #f6f6f6;
  padding: 0px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.BlocServices {
  /*
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 3ch;
  column-gap: 3ch;
  justify-items: center;
  align-content: center;
  margin-top: 20px;
  */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

@media (max-width: 640px) {
  .Bloc1 {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .TexteEtBoutonBloc1 {
    padding: 0px 20px;
  }

  .ImageBloc1 {
    border-radius: 0px 0px 0px 80px;
    border-left: 8px solid #2db5ba;
    box-shadow: -8px 0px 0px 0 rgba(156, 191, 22, 1);
    width: 100%;
    margin-left: 0px;
  }

  .Bloc2 {
    margin-top: -20px;
    padding: 60px 20px;
    background-color: #f6f6f6;
  }

  .Bloc3 {
    background-color: #f6f6f6;
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -60px;
  }

  .BlocServices {
    width: 100%;
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 3ch;
    justify-items: center;
    align-content: center;
  }
}
