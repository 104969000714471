.BlocInformationCentre {
  padding: 30px;
  flex: 1;
}

.Adresse,
.Contact,
.Horaires {
  margin-top: 30px;
}

.BlocTitreAcces {
  display: flex;
  flex-direction: row;
}

.SeparateurTitreAcces {
  border-bottom: 1px solid #ddd;
  flex: 1;
  height: 20px;
}

.TitreAcces {
  padding: 0px 20px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 700;
  color: #a1bb1a;
}

.DataVert {
  color: #a1bb1a;
  font-weight: 500;
}

.Acces {
  margin: 50px 0px;
}

.BlocCentreEtMap {
  display: flex;
}

.Carte {
  flex: 1;
  padding: 20px;
}

.BlocPhotos {
  margin-top: 50px;
  margin-bottom: 4px;
}

@media (max-width: 640px) {
  .BlocCentreEtMap {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #ddd;
    margin-top: 20px;
  }

  .Carte {
    display: none;
  }
}
