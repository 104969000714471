.PiedPage {
  background-color: #2db7bc;
  padding: 40px 0px 40px 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.BoutonLinkedin {
  min-height: 50px;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid white;
  padding: 5px 20px 5px 10px;
  font-weight: 500;
  border-radius: 10px;
  gap: 10px;
  width: fit-content;
  margin-top: 30px;
  cursor: pointer;
}

.BoutonLinkedin:hover {
  background-color: #ffffff20;
}

.LogoEtTexte {
  width: 50%;
  box-sizing: border-box;
  flex: 3;
}

.Texte {
  color: white;
  margin-top: 40px;
  font-weight: 500;
  font-weight: 300;
}

.Copyright {
  font-weight: 300;
  color: white;
  margin-top: 30px;
}

.BlocMentions {
  border-top: 1px solid #ffffff50;
  margin-top: 20px;
  padding-top: 20px;
  font-size: 0.9em;
}

.Atelier {
  color: white;
}

.MentionsLegales {
  color: white;
}

.LogoBlanc {
  flex: 1;
}

@media (max-width: 640px) {
  .PiedPage {
    background-color: #2db7bc;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .LogoEtTexte {
    width: 100%;
  }

  .LogoBlanc {
    display: none;
  }
}
