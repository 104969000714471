.Container {
  box-sizing: border-box;
  padding-bottom: 10px;
}

.Formation {
  background-color: white;
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 10px;
  box-sizing: border-box;
  height: 100%;
}

.Nom {
  font-size: 0.8em;
  font-weight: 500;
}

.Heure {
  font-size: 0.6em;
  color: #666;
}

.Illustration {
  display: none;
}
