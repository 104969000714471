.CentreEtActualite {
  margin: 20px 0px;
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 20px;
}

.Actualite {
  background-color: white;
  margin-top: 20px;
  padding: 10px 20px;
  color: 005799;
  font-weight: 700;
  font-size: 16px;
  border: 2px solid #005799;
  color: #005799;
  text-align: center;
}

.Centre {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.Logo {
  flex: 1;
  padding-left: 40px;
}

.Icone {
  margin-right: 10px;
  color: #8bb621;
}

.Info {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.Bloc {
  font-size: medium;
  display: flex;
  color: #666666;
  margin: 10px;
}

.Region {
  text-transform: uppercase;
}

.TitreBloc {
  font-weight: 300;
  font-size: 1em;
  color: #06689e;
  display: flex;
  align-items: center;
}

.BlocOuverture {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 2px dashed #ddd;
}

.Boutons {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  align-items: center;
  justify-content: center;
}

@media (max-width: 640px) {
  .Centre {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 0px;
  }
  .Logo {
    flex: 1;
    padding-left: 0px;
  }
  .Bloc {
    font-size: large;
    display: flex;
    color: #66666690;
    margin: 10px;
  }
}
