.Bloc1,
.Bloc2,
.Bloc3,
.Bloc4 {
  padding: 40px;
}

.ImageBloc1 {
  width: 100%;
}

@media (max-width: 640px) {
  .Bloc1,
  .Bloc2,
  .Bloc3,
  .Bloc4 {
    padding: 40px 20px;
  }
}
