.PanneauMenu {
  background-color: white;
  min-width: 200px;
  padding: 20px;
  max-width: 90vw;
  background-color: #ffffff40;
  box-sizing: border-box;
}

.EntetePanneauMenu {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Menu {
  font-size: 20px;
}

.Logo {
  width: auto;
  border: 1px solid red;
}

.MenuItem {
  min-height: 60px;
  color: #005799;
}

.MenuItemProfessionnel {
  color: #2db7bc;
  min-height: 60px;
}
