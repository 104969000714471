.Bloc1 {
  padding: 40px;
}

.Bloc2 {
  margin-top: 60px;
}

.ImageEtTitreBloc2 {
  position: relative;
}

.ContainerImageBloc2 {
  overflow: hidden;
  width: 100%;
}

.ImageBloc2 {
  width: 100%;
}

.TitreBloc2 {
  background-color: #00000080;
  position: absolute;
  width: 100%;
  bottom: 5px;
  left: 0px;
  padding: 20px 60px;
  box-sizing: border-box;
  min-height: 160px;
  display: flex;
  align-items: center;
}

.TexteBloc2 {
  padding: 40px;
}

.BlocCentres {
  padding: 20px 40px;
  background-color: #f6f6f6;
}

.BlocContact {
  background-color: #005799;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.BlocArticles {
  padding: 40px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 60px;
}

.IllustrationArticle {
  width: 30%;
  border-right: 8px solid #005799;
  border-radius: 0px 0px 40px 0px;
  height: auto;
}
.IllustrationArticleDroite {
  width: 30%;
  border-left: 8px solid #005799;
  border-radius: 0px 0px 0px 40px;
  height: auto;
}

.GallerieArticle {
  min-width: 50%;
  max-width: 50%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.ImageGallerie {
  max-width: 45%;
}

@media (max-width: 640px) {
  .Bloc1 {
    padding: 20px;
  }

  .ContainerImageBloc2 {
    overflow: hidden;
    height: 240px;
  }

  .ImageBloc2 {
    width: auto !important;
    height: 240px;
  }
  .TitreBloc2 {
    background-color: #00000060;
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0px;
    left: 0px;
    padding: 20px 20px;
    box-sizing: border-box;
  }
  .TexteBloc2 {
    padding: 20px;
  }
  .BlocCentres {
    padding: 20px;
  }

  .BlocArticles {
    padding: 20px;
    flex-direction: column;
  }

  .IllustrationArticle {
    width: 100%;
    border-right: 8px solid #005799;
    border-radius: 0px 0px 40px 0px;
    margin-right: 60px;
    height: auto;
  }

  .IllustrationArticleDroite {
    width: 100%;
    border-left: 8px solid #005799;
    border-radius: 0px 0px 0px 40px;
    height: auto;
  }

  .GallerieArticle {
    min-width: 100%;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .ImageGallerie {
    max-width: 100%;
  }
}
