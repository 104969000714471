.MenuProfessionnel {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 40px;
  padding-top: 60px;
  gap: 20px;
}

.ItemProfessionnel {
  background: #d8d8d8;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.ContainerImage {
  width: 180px;
  height: 120px;
  overflow: hidden;
}

.ItemProfessionnel:hover {
  box-shadow: 2px 4px 12px 0 rgba(0, 0, 0, 0.2);
  opacity: 1;
}

.Image {
  border-radius: 8px 8px 0px;
  width: 100%;
  height: auto;
}

.TexteItem {
  background-color: #8db720;
  background-color: #2db7bc;
  height: 30px;
  opacity: 0.9;
  color: white;
  font-family: "Lato";
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 0px 0px 8px 8px;
  text-decoration: none;
}

@media (max-width: 640px) {
  .MenuProfessionnel {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 20px;
    padding-top: 0px;
    gap: 10px;
  }

  .ContainerImage {
    width: 150px;
    height: 100px;
    overflow: hidden;
  }

  .TexteItem {
    font-size: 16px;
  }
}
