.Bloc1 {
  position: relative;
  width: 90%;
  box-sizing: border-box;
}

.ImageBloc1 {
  width: 100%;
  border-radius: 0px 0px 256px 0px;
  border-right: 8px solid #a2bb03;
  box-shadow: 8px 0px 0px 0 rgba(43, 183, 188, 1);
}

.TitreEtBoutonBloc1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%); /* This is a shorthand of*/
  height: 100%;
  width: 100%;
  background-color: #00579960;
  border-radius: 0px 0px 256px 0px;
  gap: 120px;
  box-sizing: border-box;
}

.BlocActualite {
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 40px;
  border-bottom: 8px solid #005799;
}

.BoutonsActualite {
  width: 100%;
  border: 0px solid red;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 40px;
  align-items: center;
  margin-top: 20px;
}

.Bloc2 {
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.TitreEtTexteBloc2 {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: 30px;
}

.ImageBloc2 {
  width: 40%;
  border-radius: 0px 0px 0px 180px;
  border-left: 8px solid #005799;
  border-bottom: 2px solid #005799;
}

.Bloc3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px 70px;
}

.AvantagesBloc3 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 50px;
  row-gap: 3ch;
  column-gap: 6ch;
}

.Bloc4 {
  background-color: #f6f6f6;
  padding: 50px 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ServicesBloc4 {
  width: 100%;
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 3ch;
  column-gap: 3ch;
  justify-items: center;
  align-content: center;
}

.BlocPartenaires {
  padding: 60px;
}

@media (max-width: 640px) {
  .BlocActualite {
    background-color: #f6f6f6;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 8px solid #005799;
  }

  .BoutonsActualite {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    gap: 20px;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 50px;
  }

  .Bloc1 {
    position: relative;
    width: 100%;
  }
  .ImageBloc1 {
    width: 100%;
    box-sizing: border-box;
    border-radius: 0px 0px 100px 0px;
    border-right: 8px solid #a2bb03;
    box-shadow: 8px 0px 0px 0 rgba(43, 183, 188, 1);
  }

  .TitreEtBoutonBloc1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%; /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */
    transform: translate(-50%, -50%); /* This is a shorthand of*/
    height: 100%;
    width: 100%;
    background-color: #00579960;
    border-radius: 0px 0px 100px 0px;
    padding: 20px;
    gap: 20px;
  }

  .Bloc2 {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .TitreEtTexteBloc2 {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left: 30px;
  }
  .TitreEtTexteBloc2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
    margin-left: 0px;
  }

  .ImageBloc2 {
    width: 100%;
  }

  .Bloc3 {
    margin: 70px 30px 20px 20px;
  }

  .AvantagesBloc3 {
    grid-template-columns: 1fr;
  }

  .Bloc4 {
    background-color: #f6f6f6;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }

  .ServicesBloc4 {
    width: auto;
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 3ch;
  }

  .BlocPartenaires {
    padding: 30px;
  }
}
