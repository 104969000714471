.TrainBus {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ContainerImage {
  display: flex;
  align-items: center;
}

@media (max-width: 640px) {
  .TrainBus {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-bottom: 20px;
  }
}
