.Bloc1 {
  background-color: #f6f6f6;
  margin-top: 60px;
  padding: 40px 40px 40px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.BlocFormations {
  padding: 40px;
  background-color: #2db7bc20;
}

.ImageBloc1 {
  border-right: 8px solid #a2bb03;
  border-radius: 0px 0px 40px 0px;
  margin-right: 60px;
  height: auto;
}

.BlocContact {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 640px) {
  .Bloc1 {
    flex-direction: column;
    padding: 0px 20px 20px 20px;
  }
  .ImageBloc1 {
    margin-bottom: 40px;
    margin-left: -20px;
  }
  .BlocFormations {
    padding: 20px;
  }
}
