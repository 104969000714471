.BlocIllustration {
  position: relative;
  height: fit-content;
  overflow: hidden;
}

.ImageIllustration {
  display: flex;
}

.ImageIllustrationMobile {
  display: none;
}

.BlocTitreIllustration {
  box-sizing: border-box;
  position: absolute;
  display: flex;
  align-items: center;
  padding-left: 40px;
  bottom: 0px;
  left: 0;
  height: 50px;
  width: 100%;
  background-image: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.7) 20%,
    rgba(0, 0, 0, 0) 90%
  );
  display: none;
}

.ListeCentres {
  margin-top: 40px;
}

.BlocUrgence {
  border: 2px solid #f44336;
  border-radius: 10px;
  color: #f44336;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 40px;
  font-weight: 500;
  text-align: center;
  width: fit-content;
  margin: 40px auto;
}

.TitreUrgence {
  font-size: 20px;
  color: #f44336;
  letter-spacing: 0;
  text-transform: uppercase;
  font-weight: 700;
}

.BlocCentres {
  background-color: #f6f6f6;
  padding: 40px 60px;
}

.MessageAucunResultat {
  text-align: center;
  font-size: 30px;
  color: #666;
  margin-top: 100px;
}

.SousTitreMessageAucunResultat {
  text-align: center;
  margin-bottom: 200px;
}

@media (max-width: 640px) {
  .ImageIllustration {
    display: none;
  }

  .ImageIllustrationMobile {
    display: flex;
  }
  .BlocTitreIllustration {
    box-sizing: border-box;
    position: absolute;
    display: flex;
    align-items: center;
    padding-left: 20px;
    bottom: 0px;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.6) 20%,
      rgba(0, 0, 0, 0) 90%
    );
  }

  .BlocUrgence {
    width: 90%;
  }
  .BlocCentres {
    padding: 40px 20px;
  }
}
