.Service {
  max-width: 340px;
  border: 1px solid #ddd;
  background-color: white;
  border-radius: 8px;
}
.BoutonAfficher {
  width: 100%;
  text-align: center;
  padding: 10px 0px;
  margin-top: 10px;
  color: #005799;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}
