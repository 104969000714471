.BlocSoins {
  padding: 40px;
}

.BlocVideo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  max-width: 100%;
  align-items: center;
  justify-content: center;
}

.BlocFormation {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 40px 40px 0px;
  background-color: #f6f6f6;
}

.ImageFormation {
  margin-right: 50px;
  border-radius: 0px 0px 40px 0px;
  border-right: 8px solid #a2bb03;
  height: 200px;
}

.ImageEtTexteFormation {
  display: flex;
}

.BlocAccompagnement {
  padding: 40px 0px 40px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.TexteEtImageAccompagnement {
  display: flex;
  flex-direction: row;
}

.ImageAccompagnement {
  border-radius: 0px 0px 0px 40px;
  border-bottom: 8px solid #005799;
  margin-left: 40px;
  height: 300px;
}

.BoutonAccompagnement {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.BlocEquipe {
  padding: 40px;
  background-color: #2db7bc10;
}

.BoutonCentre {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.BlocPartenaires {
  padding: 40px;
}

.BlocContact {
  background-color: #2db7bc20;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 70px;
}

@media (max-width: 640px) {
  .BlocSoins,
  .BlocEquipe {
    padding: 20px;
  }

  .BlocPartenaires {
    padding: 20px;
  }

  .BlocFormation {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px 40px;
    background-color: #f6f6f6;
  }

  .ImageEtTexteFormation {
    display: flex;
    flex-direction: column;
  }

  .ImageFormation {
    margin-left: -20px;
    border-radius: 0px 0px 40px 0px;
    border-right: 8px solid #a2bb03;
    margin-bottom: 20px;
  }

  .BlocAccompagnement {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .TexteEtImageAccompagnement {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }

  .ImageAccompagnement {
    border-radius: 0px 0px 0px 40px;
    border-bottom: 8px solid #005799;
    margin-right: -20px;
    height: auto;
  }
}
