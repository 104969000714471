.Partenaires {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.Logo {
  flex: 1;
  max-width: 300px;
  max-height: 100px;
  margin: 20px;
}

@media (max-width: 640px) {
  .Partenaires {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }

  .Logo {
    flex: 1;
    max-width: 300px;
    max-height: 50px;
    margin: 20px;
  }
}
